import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import ColorDayList from './pages/dashboard/ColorDayList';
import SellerDetails from './pages/dashboard/SellerDetails';
import ScanHistoryList from './pages/dashboard/ScanHistoryList';
import ScanReturnList from './pages/dashboard/ScanReturnList';
import ScanSoldList from './pages/dashboard/ScanSoldList';
import SellersList from './pages/dashboard/SellersList';
import SellerAddTransaction from './pages/dashboard/SellerAddTransaction';
import SellerAddTickets from './pages/dashboard/SellerAddTickets';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <SellersList />
      },
      {
        path: 'sellers',
        children: [
          {
            path: ':sellerId',
            children: [
              {
                path: '/',
                element: <SellerDetails />
              },
              {
                path: '/newtransaction',
                element: <SellerAddTransaction />
              },
              {
                path: '/addtickets',
                element: <SellerAddTickets />
              }
            ]
          }
        ]
      },
      {
        path: 'colorday',
        element: <ColorDayList />
      },
      {
        path: 'unsold',
        element: <SellersList />
      },
      {
        path: 'scan',
        children: [
          {
            path: '/',
            element: <ScanHistoryList />
          },
          {
            path: 'sold',
            element: <ScanSoldList />
          },
          {
            path: ':return',
            element: <ScanReturnList />
          }
        ]
      },
    ]
  }
];

export default routes;
