import { useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import type { Ticket, TicketStatus } from '../../../types/ticket';
import MoreMenu from '../../MoreMenu';
import Scrollbar from '../../Scrollbar';
import ScanListBulkActions from './ScanListBulkActions';
import Label from 'src/components/Label';

interface ScanListTableProps {
  tickets: Ticket[];
}

const getStatusLabel = (ticketStatus: TicketStatus): JSX.Element => {
  if (!ticketStatus) {
    return <></>;
  }
  const map = {
    Returned: {
      color: 'error',
      text: 'Returned'
    },
    Sold: {
      color: 'success',
      text: 'Sold'
    },
    Selling: {
      color: 'warning',
      text: 'Selling'
    },
    Lost: {
      color: 'error',
      text: 'Lost'
    },
    Unassigned: {
      color: 'warning',
      text: 'Unassigned'
    }
  };

  const { text, color }: any = map[ticketStatus];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

const applyPagination = (
  tickets: Ticket[],
  page: number,
  limit: number
): Ticket[] => tickets
  .slice(page * limit, page * limit + limit);

const ScanListTable: FC<ScanListTableProps> = (props) => {
  const { tickets, ...other } = props;
  const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  const handleSelectAllTickets = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedTickets(event.target.checked
      ? tickets.map((ticket) => ticket.ticketNumber)
      : []);
  };

  const handleSelectOneTicket = (
    event: ChangeEvent<HTMLInputElement>,
    ticketId: number
  ): void => {
    if (!selectedTickets.includes(ticketId)) {
      setSelectedTickets((prevSelected) => [...prevSelected, ticketId]);
    } else {
      setSelectedTickets((prevSelected) => prevSelected.filter((id) => id !== ticketId));
    }
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedTickets = applyPagination(tickets, page, limit);
  const enableBulkActions = selectedTickets.length > 0;
  const selectedSomeTickets = selectedTickets.length > 0 && selectedTickets.length < tickets.length;
  const selectedAllTickets = selectedTickets.length === tickets.length;

  return (
    <>
      <Card {...other}>
        <CardHeader
          action={<MoreMenu />}
          title="Tickets"
        />
        <Divider />
        <Scrollbar>
          <Box sx={{ minWidth: 1150 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllTickets}
                      color="primary"
                      indeterminate={selectedSomeTickets}
                      onChange={handleSelectAllTickets}
                    />
                  </TableCell>
                  <TableCell>
                    Number
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Grade
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    Updated
                  </TableCell>
                  {/* <TableCell align="right">
                    Actions
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTickets.map((ticket) => {
                  const isTicketSelected = selectedTickets.includes(ticket.ticketNumber);

                  return (
                    <TableRow
                      hover
                      key={ticket.ticketNumber}
                      selected={selectedTickets.indexOf(ticket.ticketNumber) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isTicketSelected}
                          color="primary"
                          onChange={(event): void => handleSelectOneTicket(event, ticket.ticketNumber)}
                          value={isTicketSelected}
                        />
                      </TableCell>
                      <TableCell>
                        {ticket.ticketNumber}
                      </TableCell>
                      <TableCell>
                        {`${ticket.lastName}, ${ticket.firstName}`}
                      </TableCell>
                      <TableCell>
                        {ticket.grade}
                      </TableCell>
                      <TableCell>
                        {/* {ticket.status} */}
                        {getStatusLabel(ticket.status)}
                      </TableCell>
                      <TableCell>
                        {format(new Date(ticket.updated), 'dd MMM yyyy | HH:mm')}
                      </TableCell>
                      {/* <TableCell align="right">
                        <IconButton>
                          <PencilAltIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          component={RouterLink}
                          to="/dashboard/tickets/1"
                        >
                          <ArrowRightIcon fontSize="small" />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={tickets.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <ScanListBulkActions
        open={enableBulkActions}
        selected={selectedTickets}
      />
    </>
  );
};

ScanListTable.propTypes = {
  tickets: PropTypes.array.isRequired
};

export default ScanListTable;
