import type { FC } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Grid,
  TextField
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

interface SellerAddTransactionFormProps {
  sellerId: string;
}

const SellerAddTransactionForm: FC<SellerAddTransactionFormProps> = (props) => {
  const { sellerId, ...other } = props;
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        amount: '',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            amount: Yup.string(),
          })
      }
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          const requestData = {
            seller: sellerId,
            amount: values.amount
          };
          await axios.post('https://zoosn199vb.execute-api.us-east-1.amazonaws.com/prod/transactions/add', requestData);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          navigate('../');
          toast.success('Transaction Recorded!');
        } catch (err) {
          console.error(err);
          toast.error('Something went wrong!');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.amount && errors.amount)}
                    fullWidth
                    helperText={touched.amount && errors.amount}
                    label="Amount"
                    name="amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.amount}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Add Payment
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SellerAddTransactionForm.propTypes = {
  sellerId: PropTypes.string.isRequired
};

export default SellerAddTransactionForm;
