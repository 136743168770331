import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import axios from 'axios';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
// import { OrderItems, OrderSummary } from '../../components/dashboard/order';
import useMounted from '../../hooks/useMounted';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import gtm from '../../lib/gtm';
import { SellerDetail } from 'src/types/seller';
import SellerSummary from 'src/components/dashboard/seller/SellerSummary';
import TransactionItems from 'src/components/dashboard/seller/TransactionsItems';
import TicketItems from 'src/components/dashboard/seller/TicketItems';
import PlusIcon from 'src/icons/Plus';

const SellerDetails: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [sellerDetails, setSellerDetails] = useState<SellerDetail | null>(null);
  const { sellerId } = useParams();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getSeller = useCallback(async () => {
    try {
      const response = await axios.get<SellerDetail>(`https://zoosn199vb.execute-api.us-east-1.amazonaws.com/prod/sellers/${sellerId}`);
      if (mounted.current) {
        setSellerDetails(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSeller();
  }, [getSeller]);

  if (!sellerDetails) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Seller Details | OLC Raffle</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Seller Details
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Reports
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Sellers
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Orders
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  to="newtransaction"
                  variant="contained"
                >
                  Add Payment
                </Button>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  to="addtickets"
                  variant="contained"
                >
                  Add Tickets
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xl={3}
                xs={12}
              >
                <SellerSummary sellerDetail={sellerDetails} />
              </Grid>
              <Grid
                item
                md={8}
                xl={9}
                xs={12}
              >
                <TransactionItems transactionItems={sellerDetails.transactions} />
                <br />
                <TicketItems ticketItems={sellerDetails.tickets} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SellerDetails;
