import type { FC } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import { SellerDetail } from 'src/types/seller';

interface SellerDetailsProps {
  sellerDetail: SellerDetail;
}

const SellerSummary: FC<SellerDetailsProps> = (props) => {
  const { sellerDetail, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader title="Seller info" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Seller
              </Typography>
            </TableCell>
            <TableCell>
              {`${sellerDetail.seller.lastName}, ${sellerDetail.seller.firstName}`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                ID
              </Typography>
            </TableCell>
            <TableCell>
              #
              {sellerDetail.seller.id}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Grade
              </Typography>
            </TableCell>
            <TableCell>
              {sellerDetail.seller.grade}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Paid
              </Typography>
            </TableCell>
            <TableCell>
              {sellerDetail.seller.paid}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell>
              {sellerDetail.seller.status}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Created
              </Typography>
            </TableCell>
            <TableCell>
              {format(new Date(sellerDetail.seller.created), 'dd/MM/yyyy | HH:mm')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

SellerSummary.propTypes = {
  // @ts-ignore
  sellerDetail: PropTypes.object.isRequired
};

export default SellerSummary;
