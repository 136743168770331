import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import axios from 'axios';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
// import { ticketApi } from '../../__fakeApi__/ticketApi';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import gtm from '../../lib/gtm';
import barcode from 'barcode-hid-reader';
import { Ticket } from 'src/types/ticket';
import useMounted from 'src/hooks/useMounted';
import { SellerDetail } from 'src/types/seller';
import { SellerAssignTicketsForm } from 'src/components/dashboard/seller';
import TicketItems from 'src/components/dashboard/seller/TicketItems';

const SellerAddTickets: FC = () => {
  const mounted = useMounted();
  const { sellerId } = useParams();
  const { settings } = useSettings();
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [sellerDetails, setSellerDetails] = useState<SellerDetail | null>({ seller: { id: sellerId, firstName: '', lastName: '', grade: '', status: 'owes' }, tickets: null, transactions: null });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleBarcodeScan = async (data) => {
    // If Barcode was already scanned, skip it
    if (tickets.some((e) => e.ticketNumber === parseInt(data, 10))) {
      return;
    }

    const requestData = {
      seller: sellerId,
      startNumber: data,
      count: 1,
      status: 1
    };
    const response = await axios.post('https://zoosn199vb.execute-api.us-east-1.amazonaws.com/prod/tickets/assign', requestData);
    setTickets((arr) => [response.data[0], ...arr]);
  };

  const getSeller = useCallback(async () => {
    try {
      const response = await axios.get<SellerDetail>(`https://zoosn199vb.execute-api.us-east-1.amazonaws.com/prod/sellers/${sellerId}`);
      if (mounted.current) {
        setSellerDetails(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSeller();
    barcode.startCapturing(document, {
      callback: handleBarcodeScan
    }, [getSeller]);

    return function cleanup() {
      barcode.stopCapturing();
    };
  }, [tickets]);

  return (
    <>
      <Helmet>
        <title>Add Tickets | OLC Raffle</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Add Tickets
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Reports
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Sellers
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to={`/sellers/${sellerId}`}
                  variant="subtitle2"
                >
                  {`${sellerDetails.seller.firstName} ${sellerDetails.seller.lastName}`}
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Add Tickets
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xl={3}
                xs={12}
              >
                <SellerAssignTicketsForm
                  sellerId={sellerId}
                  setTickets={setTickets}
                />
              </Grid>
              <Grid
                item
                md={8}
                xl={9}
                xs={12}
              >
                <TicketItems ticketItems={tickets} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SellerAddTickets;
