import { useEffect, useState } from 'react';
import type { FC } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
// import { ticketApi } from '../../__fakeApi__/ticketApi';
import { ScanListTable } from '../../components/dashboard/scan';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import PlusIcon from '../../icons/Plus';
import gtm from '../../lib/gtm';
import barcode from 'barcode-hid-reader';
import { Ticket } from 'src/types/ticket';

const ScanReturnList: FC = () => {
  // const mounted = useMounted();
  const { settings } = useSettings();
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [soldInput, setSoldInput] = useState('');

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleBarcodeScan = async (data) => {
    // If Barcode was already scanned, skip it
    if (tickets.some((e) => e.ticketNumber === parseInt(data, 10))) {
      return;
    }

    const requestData = {
      ticketId: data,
      status: 'Returned',
      eventId: 1
    };
    const response = await axios.post<Ticket>('https://zoosn199vb.execute-api.us-east-1.amazonaws.com/prod/tickets/status', requestData);
    setTickets((arr) => [response.data[0][0], ...arr]);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleBarcodeScan(soldInput);
    setSoldInput('');
  };

  useEffect(() => {
    // getTickets();
    barcode.startCapturing(document, {
      callback: handleBarcodeScan
    });

    return function cleanup() {
      barcode.stopCapturing();
    };
  }, [tickets]);

  return (
    <>
      <Helmet>
        <title>Scan: Ticket Return | OLC Raffle Tickets</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Ticket Return
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/scan"
                  variant="subtitle2"
                >
                  Scan
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Ticket Return
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Ticket Number"
                  name="soldInput"
                  value={soldInput}
                  variant="outlined"
                  onChange={(e) => setSoldInput(e.target.value)}
                />
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    type="submit"
                  >
                    Return
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ScanListTable tickets={tickets} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ScanReturnList;
