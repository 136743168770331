import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import Scrollbar from '../../Scrollbar';
import { Ticket } from 'src/types/ticket';
import { format } from 'date-fns';
// import getStatusLabel from './StatusLabel';

interface TicketItemsProps {
  ticketItems: Ticket[];
}

const TicketItems: FC<TicketItemsProps> = (props) => {
  const { ticketItems, ...other } = props;
  return (
    <Card {...other}>
      <CardHeader title="Tickets" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Ticket Number
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketItems.map((item) => (
                <TableRow key={item.ticketNumber}>
                  <TableCell>
                    {item.ticketNumber}
                  </TableCell>
                  <TableCell>
                    {/* {getStatusLabel(item.status)} */}
                    {item.status}
                  </TableCell>
                  <TableCell>
                    {format(new Date(item.updated), 'dd/MM/yyyy | HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={ticketItems.length}
        onPageChange={(): void => { }}
        onRowsPerPageChange={(): void => { }}
        page={0}
        rowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

TicketItems.propTypes = {
  ticketItems: PropTypes.array.isRequired
};

export default TicketItems;
