import type { FC } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  TextField
} from '@material-ui/core';

interface SellerAssignTicketsFormProps {
  sellerId: string;
  setTickets: any;
}

const SellerAssignTicketsForm: FC<SellerAssignTicketsFormProps> = (props) => {
  const { setTickets, sellerId, ...other } = props;
  // const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        startTicketNumber: '',
        count: '',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            startTicketNumber: Yup.number().min(1).required(),
            count: Yup.number().min(1).required()
          })
      }
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          const requestData = {
            seller: sellerId,
            startNumber: values.startTicketNumber,
            count: values.count,
            status: 1
          };
          const result = await axios.post('https://zoosn199vb.execute-api.us-east-1.amazonaws.com/prod/tickets/assign', requestData);
          setTickets(result.data);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success('Tickets Assigned!');
          // navigate('/dashboard/products');
        } catch (err) {
          console.error(err);
          toast.error('Something went wrong!');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Card>
            <CardHeader title="Add Ticket Form" />
            <CardContent>
              <Box sx={{ mt: 2 }}>
                <TextField
                  error={Boolean(touched.startTicketNumber && errors.startTicketNumber)}
                  fullWidth
                  helperText={touched.startTicketNumber && errors.startTicketNumber}
                  label="Starting Ticket Number"
                  name="startTicketNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.startTicketNumber}
                  variant="outlined"
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  error={Boolean(touched.count && errors.count)}
                  fullWidth
                  helperText={touched.count && errors.count}
                  label="Count"
                  name="count"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.count}
                  variant="outlined"
                  autoComplete="off"
                />
              </Box>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 3
            }}
          >
            <Button
              color="primary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              Add Tickets
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

SellerAssignTicketsForm.propTypes = {
  sellerId: PropTypes.string.isRequired,
  setTickets: PropTypes.any.isRequired
};

export default SellerAssignTicketsForm;
