import type { FC } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import Scrollbar from '../../Scrollbar';
import { Transaction } from 'src/types/transaction';
import { format } from 'date-fns';

interface TransactionItemsProps {
  transactionItems: Transaction[];
}

const TransactionItems: FC<TransactionItemsProps> = (props) => {
  const { transactionItems, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader title="Transaction items" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Amount
                </TableCell>
                <TableCell>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {numeral(item.amount)
                      .format('$0,0.00')}
                  </TableCell>
                  <TableCell>
                    {format(new Date(item.created), 'dd/MM/yyyy | HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={transactionItems.length}
        onPageChange={(): void => { }}
        onRowsPerPageChange={(): void => { }}
        page={0}
        rowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

TransactionItems.propTypes = {
  transactionItems: PropTypes.array.isRequired
};

export default TransactionItems;
