import { useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField
} from '@material-ui/core';
import ArrowRightIcon from '../../../icons/ArrowRight';
import PencilAltIcon from '../../../icons/PencilAlt';
import SearchIcon from '../../../icons/Search';
import type { Seller } from '../../../types/seller';
import Scrollbar from '../../Scrollbar';
import { SellerStatus } from 'src/types/seller';
import Label from '../../Label';

interface SellerListTableProps {
  sellers: Seller[];
}

type Sort =
  | 'updatedAt|desc'
  | 'updatedAt|asc'
  | 'orders|desc'
  | 'orders|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'KG',
    value: 'KG'
  },
  {
    label: '1st',
    value: '1'
  },
  {
    label: '2nd',
    value: '2'
  },
  {
    label: '3rd',
    value: '3'
  },
  {
    label: '4th',
    value: '4'
  },
  {
    label: '5th',
    value: '5'
  },
  {
    label: '6th',
    value: '6'
  },
  {
    label: '7th',
    value: '7'
  },
  {
    label: '8th',
    value: '8'
  },
];

const sortOptions: SortOption[] = [
  {
    label: 'Last update (newest)',
    value: 'updatedAt|desc'
  },
  {
    label: 'Last update (oldest)',
    value: 'updatedAt|asc'
  },
  {
    label: 'Total orders (highest)',
    value: 'orders|desc'
  },
  {
    label: 'Total orders (lowest)',
    value: 'orders|asc'
  }
];

const getStatusLabel = (sellerStatus: SellerStatus): JSX.Element => {
  const map = {
    owes: {
      color: 'warning',
      text: 'Owes'
    },
    paid: {
      color: 'success',
      text: 'Paid'
    },
    overpaid: {
      color: 'success',
      text: 'Over Paid'
    },
    paidtickets: {
      color: 'warning',
      text: 'Paid/Tickets Out'
    },
    overpaidtickets: {
      color: 'warning',
      text: 'Over Paid/Tickets Out'
    },
    lost: {
      color: 'error',
      text: 'Lost'
    },
    none: {
      color: 'secondary',
      text: 'None'
    }
  };

  const { text, color }: any = map[sellerStatus];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

const applyFilters = (
  sellers: Seller[],
  query: string,
  filters: any
): Seller[] => sellers
  .filter((seller) => {
    let matches = true;

    if (query) {
      const properties = ['firstName', 'lastName'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (seller[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (value && seller.grade !== key) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (
  sellers: Seller[],
  page: number,
  limit: number
): Seller[] => sellers
  .slice(page * limit, page * limit + limit);

const descendingComparator = (
  a: Seller,
  b: Seller,
  orderBy: string
): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => (
  order === 'desc'
    ? (a: Seller, b: Seller) => descendingComparator(a, b, orderBy)
    : (a: Seller, b: Seller) => -descendingComparator(a, b, orderBy)
);

const applySort = (sellers: Seller[], sort: Sort): Seller[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = sellers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const SellerListTable: FC<SellerListTableProps> = (props) => {
  const { sellers, ...other } = props;
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [selectedSellers, setSelectedSellers] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    KG: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
  });

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    const updatedFilters = {
      ...filters,
      KG: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setSelectedSellers([]);
    setCurrentTab(value);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };

  const handleSelectAllSellers = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedSellers(event.target.checked
      ? sellers.map((seller) => seller.id)
      : []);
  };

  const handleSelectOneSeller = (
    event: ChangeEvent<HTMLInputElement>,
    sellerId: string
  ): void => {
    if (!selectedSellers.includes(sellerId)) {
      setSelectedSellers((prevSelected) => [...prevSelected, sellerId]);
    } else {
      setSelectedSellers((prevSelected) => prevSelected.filter((id) => id !== sellerId));
    }
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredSellers = applyFilters(sellers, query, filters);
  const sortedSellers = applySort(filteredSellers, sort);
  const paginatedSellers = applyPagination(sortedSellers, page, limit);
  const enableBulkActions = selectedSellers.length > 0;
  const selectedSomeSellers = selectedSellers.length > 0
    && selectedSellers.length < sellers.length;
  const selectedAllSellers = selectedSellers.length === sellers.length;

  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search sellers"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Sort By"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllSellers}
              color="primary"
              indeterminate={selectedSomeSellers}
              onChange={handleSelectAllSellers}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Delete
            </Button>
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllSellers}
                    color="primary"
                    indeterminate={selectedSomeSellers}
                    onChange={handleSelectAllSellers}
                  />
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Grade
                </TableCell>
                <TableCell>
                  Paid
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSellers.map((seller) => {
                const isSellerSelected = selectedSellers.includes(seller.id);

                return (
                  <TableRow
                    hover
                    key={seller.id}
                    selected={isSellerSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSellerSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneSeller(
                          event,
                          seller.id
                        )}
                        value={isSellerSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {`${seller.lastName}, ${seller.firstName}`}
                    </TableCell>
                    <TableCell>
                      {seller.grade}
                    </TableCell>
                    <TableCell>
                      {numeral(seller.paid).format('$0,0.00')}
                    </TableCell>
                    <TableCell>
                      {getStatusLabel(seller.status)}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/sellers/${seller.id}/edit`}
                      >
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        component={RouterLink}
                        to={`/sellers/${seller.id}`}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredSellers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

SellerListTable.propTypes = {
  sellers: PropTypes.array.isRequired
};

export default SellerListTable;
